import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Heading from "../../components/heading"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Familienfuehrung = () => {
  const data = useStaticQuery(graphql`
    query Familienfuehrung {
      doeringCover: file(
        relativePath: { eq: "veranstaltungen/doering-hoelderlin.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Hölderlin ist nur etwas für Erwachsene, und die Kinder müssen zuhause bleiben? Denkste!
      </Paragraph>
      <Paragraph>
        Bei einer Familienführung begeben wir uns auf die Spuren des rätselhaften Dichters Friedrich Hölderlin, 
        der in dem schönen Turm am Neckar sein halbes Leben verbracht hat. Wir werden gemeinsam seiner Arbeit 
        und seiner Liebe nachspüren, seine Gedichte mit allen Sinnen erfahren und selbst mit Silben, 
        Versen und Rhythmen experimentieren. In den verschiedenen Räumen des Museums auf drei Stockwerken wie 
        auch im Museumsgarten wird es für Große, Kleine und alle dazwischen viel Spannendes zu entdecken geben.
      </Paragraph>
      <Paragraph>
        In Kooperation mit der Familienbildungsstätte Tübingen
      </Paragraph>
      <Stack space={6}>
              <Heading as="h2" level={5}>
                Anmeldung
              </Heading>
              <Paragraph>
                  Buchung nur über die FBS:{" "}
                  <ExternalLink href="https://www.fbs-tuebingen.de/familienaktivitaeten/kw/bereich/kursdetails/kurs/242-2512/kursname/Familienfuehrung+im+Hoelderlinturm/kategorie-id/43/#inhalt">
                    <span style={{color: '#F7A61B'}}> Zur Buchung</span> 
                  </ExternalLink>
              </Paragraph>
      </Stack>
    </Stack>
  )
}

export default Familienfuehrung