import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import { ExternalLink } from 'react-external-link';
import Heading from "../../components/heading"
import Image from "../../components/image"
import Grid from "../../components/grid"

const Nature = () => {
  const data = useStaticQuery(graphql`
    query Nature {
      nature: file(
        relativePath: { eq: "veranstaltungen/stefan_knoedler.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Anja Utlers Gedichte versuchen, eine Sprache für unaussprechliche Erfahrungen zu finden. 
        Schon früh setzte sich so mit Naturerfahrung im Angesicht der ökologischen Katastrophe auseinander, 
        so in ihrem 2004 erschienenen Gedichtband münden - entzüngeln. In ihren Gedichten möchte sie die Distanz zwischen Mensch 
        und Umwelt aufheben. kommen sehen - Lobgesang (2020), ein hymnischer Monolog, 
        der in einer dystopischen Zukunftswelt nach der Klimakatastrophe angesiedelt ist, macht diese Erfahrung konkret.
      </Paragraph>
      <Paragraph>
        Anja Utlers Lyrik ist geschrieben, um gehört zu werden: Ihre Gedichtbände sind nicht nur als Druckfassung, 
        sondern teilweise auch als von ihr selbst eingesprochene Hörfassung erhältlich. 
        Auch als Sprechwissenschaftlerin interessiert sie besonders auch der Klang von Gedichten. 
      </Paragraph>
      <Paragraph>
        Sie ist mit mehreren Literaturpreisen ausgezeichnet worden, darunter zuletzt dem Ernst-Jandl-Preis (2023) 
        und dem Peter-Huchel-Preis (2024). In Kooperation mit dem Studio Literatur und Theater der Universität Tübingen.
      </Paragraph>
    </Stack>
  )
}

export default Nature