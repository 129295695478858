import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import { ExternalLink } from 'react-external-link';
import Heading from "../../components/heading"

const Feuertaufe = () => {
  const data = useStaticQuery(graphql`
    query Feuertaufe {
      doeringCover: file(
        relativePath: { eq: "veranstaltungen/doering-hoelderlin.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        In einem bebilderten Vortrag geht Bernd Gugel gemeinsam mit Museumsleiter Florian Mittelhammer den Gründungsjahren 
        der Pompiers von Tübingen unter dem ersten Kommandanten Julius Haller nach. In den 1870er Jahren kam es zur großen Bewährungsprobe der Feuerwehr, 
        als die Stadt von einer verheerenden Reihe von Brandstiftungen heimgesucht wurde. Die schiere Menge der Brände in dieser Zeit zeigte auf, 
        wie unentbehrlich eine gut organisierte Feuerwehr für die Stadt ist und trug so maßgeblich zu ihrer Institutionalisierung bei. 
        Was aber nur wenige wissen: am Beginn dieser Brandserie stand das Feuer im Hölderlinturm. Was steckte hinter dieser Brandserie? 
        Wie ging die Feuerwehr damals bei der Brandbekämpfung vor und welche Ausrüstung hat sie genutzt?
      </Paragraph>
      <Paragraph>
        Bernd Gugel ist langjähriges Mitglied der Freiwilligen Feuerwehr Tübingen, Sportbeauftragter der Feuerwehren im Landkreis Tübingen 
        und Mitglied des Tübinger Gemeinderats.
      </Paragraph>
            <Stack space={6}>
                    <Heading as="h2" level={5}>
                      Anmeldung
                    </Heading>
                    <Paragraph>
                        Anmeldung telefonisch unter 07071/2041862, oder:{" "}
                        <ExternalLink href="https://app.cituro.com/booking/stadtmuseumtuebingen#step=1">
                          <span style={{color: '#F7A61B'}}> Hier</span> 
                        </ExternalLink>
                    </Paragraph>
            </Stack>
    </Stack>
  )
}

export default Feuertaufe