import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import { ExternalLink } from 'react-external-link';
import Heading from "../../components/heading"
import Image from "../../components/image"
import Grid from "../../components/grid"

const Oster = () => {
  const data = useStaticQuery(graphql`
    query Oster {
      oster: file(
        relativePath: { eq: "veranstaltungen/stefan_knoedler.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Rund um Ostern haben wir im ganzen Museumsgarten wieder Ostereier mit poetischen Frühlingsbotschaften versteckt. 
        Wer eines findet, kann es an der Museumskasse gegen eine kleine Belohnung eintauschen!
      </Paragraph>
    </Stack>
  )
}

export default Oster