import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import { ExternalLink } from 'react-external-link';
import Heading from "../../components/heading"
import Image from "../../components/image"
import Grid from "../../components/grid"

const Ways = () => {
  const data = useStaticQuery(graphql`
    query Ways {
      ways: file(
        relativePath: { eq: "veranstaltungen/stefan_knoedler.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        <strong>with Samantha Rose Hill and Paul Holdengräber</strong>
      </Paragraph>
      <Paragraph>
        Where are we when we are thinking? Where do thoughts come from?Join Samantha Rose Hill and Paul Holdengräber as they explore what it means to "think with," 
        drawing from Hannah Arendt’s idea of thinking as an inner dialogue. What are the risks and rewards of "thinking with" others—whether real, 
        imagined, or historical—and how do these dynamics influence our intellectual and creative lives?
      </Paragraph>
      <Paragraph>
        <strong>Samantha Rose Hill</strong> is the author of Hannah Arendt (2021) and the editor and translator of What Remains: 
        The Collected Poems of Hannah Arendt (2024). She is a visiting scholar at the Oxford Centre for Life-Writing at Wolfson College 
        and associate faculty at the Brooklyn Institute for Social Research. Her writing has appeared in The Paris Review, Aeon, Commonweal, 
        The Guardian, Guernica, Lapham’s Quarterly, LitHub, Los Angeles Review of Books, The Jewish Review of Books, and TLS. 
        She is currently working on her next book, Loneliness, forthcoming from Yale University Press.
      </Paragraph>
      <Paragraph>
        Paul Holdengräber is an interviewer, curator of public curiosity, and was the Founding Executive Director of Onassis Los Angeles (OLA). 
        Prior he was Founder and Director of The New York Public Library’s LIVE from the NYPL cultural series where he interviewed 
        and hosted over 600 events, including interviews with Patti Smith, Wes Anderson, Mike Tyson, Werner Herzog.
      </Paragraph>
      <Paragraph>
        In English<br></br>
        Location: d.a.i. hall
      </Paragraph>
      <Paragraph>
        Eine Veranstaltung des d.a.i. Tübingen in Kooperation mit dem Hölderlinturm
      </Paragraph>
    </Stack>
  )
}

export default Ways