import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import { ExternalLink } from 'react-external-link';
import Heading from "../../components/heading"

const Krueger = () => {
  const data = useStaticQuery(graphql`
    query Krueger {
      doeringCover: file(
        relativePath: { eq: "veranstaltungen/doering-hoelderlin.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Michael Krüger prägte als Lektor und Verleger, aber auch als Dichter und Schriftsteller jahrzehntelang den deutschen Literaturbetrieb 
        und bereichert ihn mit seinen Gedichten bis heute. 1986 übernahm er die Leitung des Carl Hanser-Verlags, 
        schloss Freundschaft mit Fritz Arnold, Hans Magnus Enzensberger oder Peter Handke. 
        In seiner 2024 erschienenen Autobiographie „Verabredung mit Dichtern“ erzählt er von solchen Begegnungen. 2020, 
        mit der Ausbreitung des Coronavirus, zog er sich in ein Holzhaus am Starnberger See zurück 
        und begann eine Therapie gegen seine Leukämie. Es entstand der Gedichtband „Im Wald, im Holzhaus“, 
        in dem er sich auch mit Hölderlin auseinandersetzt. Die Lesung moderiert Prof. Dr. Georg Braungart.
      </Paragraph>
      <Paragraph>
        In Kooperation mit der Lyrikhandlung am Hölderlinturm
      </Paragraph>
      <Paragraph>
        Gefördert aus Landesmitteln durch die Arbeitsstelle für literarische Museen, Archive und Gedenkstätten in Baden-Württemberg (Deutsches Literaturarchiv Marbach)
      </Paragraph>
            <Stack space={6}>
                    <Heading as="h2" level={5}>
                      Anmeldung
                    </Heading>
                    <Paragraph>
                        Die Lesung ist ausverkauft.
                    </Paragraph>
            </Stack>
    </Stack>
  )
}

export default Krueger